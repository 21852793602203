import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Person } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../../../../../../../libs/legacy-lib/src/lib/components/components.module';

@Component({
  selector: 'app-contract-help-modal',
  templateUrl: './contract-help-modal.component.html',
  styleUrls: ['./contract-help-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class ContractHelpModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public contact: Person;
  public isStepCode: boolean;
  public customCookieSettings: () => void;

  public onCustomCookieSettings() {
    this.customCookieSettings();
    this.dismiss();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public ok() {
    this.ngbActiveModal.close();
  }
}
